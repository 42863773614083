// example: best-hearing-aids, otc-hearing-aids
/* eslint-disable max-len */
import * as React from 'react';

import NextLink from 'next/link';
import { ClassNameValue } from 'tailwind-merge';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import PriceButton from 'components/widgets/price-button';
import SimplePrice from 'components/widgets/simple-price';
import { useApiData } from 'hooks/use_api_data';
import IconArrowRight from 'images/icon-arrow-right.svg';
import IconChevronDownSm from 'images/icon-chevron-down-sm.svg';
import logger from 'lib/logger';
import { loadAuthors } from 'lib/storyblok';
import { utmize } from 'lib/ts-utils';
import { formatDate, normalizeUrl, tw } from 'lib/utils';
import { Author, Blok } from 'types/blok';
import type { Listicle as ListicleType, ListicleOverride as ListicleOverrideType } from 'types/release';
import { N4ArticleStoryblok } from 'types/storyblok-component-types';

import { ArticleTitle } from './article-title';

const log = logger({ category: 'n4/Article' });

const FEATURES_TO_SHOW_INITIALLY = 4;

interface Story {
  name: string;
  full_slug: string;
  published_at: string;
  created_at: string;
  content: {
    blocks: Blok[];
  };
}

// desktop:first:bg-white desktop:first:shadow-sm
const ListicleProduct: React.FC<{ listicle: ListicleType; className: ClassNameValue; blok: N4ArticleStoryblok }> = ({ listicle, className, blok }) => (
  <div className={tw('group -mx-6 rounded-[20px] px-6', className)}>
    <div className="flex flex-col justify-between gap-[20px] border-t border-dashed border-navy-10 py-6 group-first:border-transparent group-last:border-b desktop:flex-row desktop:gap-8">
      <div className="flex gap-[20px] desktop:gap-6">
        <div className="relative flex-shrink-0">
          <HtLink href={normalizeUrl({ url: listicle.path, origin: 'listicle-image' })}>
            <ImageWrapper
              image={listicle.image}
              imgProps={{ className: 'object-cover rounded-[10px] !w-[140px] !h-[93px] desktop:!w-[160px] desktop:!h-[107px]' }}
              origin="article-header"
            />
          </HtLink>
          {!!(blok.ht_score_override || listicle.score) && (
            <CircleScoreFluid
              size="xs"
              amount={blok.ht_score_override || listicle.score}
              progress={((blok.ht_score_override || listicle.score) / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
              className="absolute left-2 top-2 shadow-[1px_2px_2px_0_rgba(0,0,0,0.15)]"
              origin="article-header"
            />
          )}
        </div>
        <div className="flex flex-col gap-[4px] desktop:gap-[2px]">
          <div className="text-[16px] leading-[140%] tracking-tight text-navy">{listicle.title}</div>
          <div className="text-[20px] leading-[140%] tracking-tight">
            <HtLink href={normalizeUrl({ url: listicle.path, origin: 'listicle-name' })}>{listicle.full_name}</HtLink>
          </div>
          <div className="text-[16px] leading-[140%] tracking-tight text-lapis desktop:font-light">
            {blok.affiliate_link_override?.url && blok.price_override ? (
              <SimplePrice
                uuid={blok._uid}
                url={blok.affiliate_link_override.url}
                position="listicle-product-list-top-link"
                origin="listicle-override"
                releaseName={blok.product_name_override || listicle.release.name}
                slug={listicle.release.slug}
                seller={blok.product_name_override || listicle.release.name}
                display="link"
                eventProperties={{ partnerType: 'override' }}
              >
                {blok.price_override}
              </SimplePrice>
            ) : (
              <PriceButton
                release={listicle.release}
                prices={listicle.release.prices}
                origin="listicle-product"
                position="listicle-product-list-top-link"
                display="link"
                useFallback
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-[14px] desktop:gap-3">
        {blok.affiliate_link_override?.url && blok.price_override ? (
          <SimplePrice
            uuid={blok._uid}
            url={blok.affiliate_link_override.url}
            position="listicle-product-list-top"
            origin="listicle-override"
            releaseName={blok.product_name_override || listicle.release.name}
            slug={listicle.release.slug}
            seller={blok.product_name_override || listicle.release.name}
            display="button"
            eventProperties={{ partnerType: 'override' }}
          />
        ) : (
          <PriceButton
            release={listicle.release}
            prices={listicle.release.prices}
            origin="article-header"
            position="listicle-product-list-top"
            className="min-h-[40px] !w-full whitespace-nowrap !px-[1.4rem] !py-[0.8rem] !text-[16px] laptop:min-w-[200px]"
            forceDirect
          />
        )}
        <a href={`#${listicle._uid}`} className="flex items-center justify-center gap-2 self-stretch text-center text-md tracking-tight text-navy">
          Read more
          <IconChevronDownSm />
        </a>
      </div>
    </div>
  </div>
);

const ListicleOverride: React.FC<{ listicle: ListicleOverrideType; className: ClassNameValue }> = ({ listicle, className }) => (
  <div className={tw('group -mx-6 rounded-[20px] px-6', className)}>
    <div className="flex flex-col justify-between gap-[20px] border-t border-dashed border-navy-10 py-6 group-first:border-transparent group-last:border-b desktop:flex-row desktop:gap-8">
      <div className="flex gap-[20px] desktop:gap-6">
        <div className="relative flex-shrink-0">
          <HtLink
            href={utmize({ url: listicle.url, content: 'listicle-override-image', term: listicle._uid, campaign: 'affiliate-override', medium: 'image' })}
          >
            <ImageWrapper
              image={listicle.image}
              imgProps={{ className: 'object-cover rounded-[10px] !w-[140px] !h-[93px] desktop:!w-[160px] desktop:!h-[107px]' }}
              origin="article-header"
            />
          </HtLink>
          {!!listicle.score && (
            <CircleScoreFluid
              size="xs"
              amount={listicle.score}
              progress={(listicle.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
              className="absolute left-2 top-2 shadow-[1px_2px_2px_0_rgba(0,0,0,0.15)]"
              origin="article-header"
            />
          )}
        </div>
        <div className="flex flex-col gap-[4px] desktop:gap-[2px]">
          <div className="text-[16px] leading-[140%] tracking-tight text-navy">{listicle.name}</div>
          <div className="text-[20px] leading-[140%] tracking-tight">
            <HtLink
              href={utmize({ url: listicle.url, content: 'listicle-override-name', term: listicle._uid, campaign: 'affiliate-override', medium: 'link' })}
            >
              {listicle.product}
            </HtLink>
          </div>
          <div className="text-[16px] leading-[140%] tracking-tight text-lapis desktop:font-light">
            <SimplePrice
              uuid={listicle._uid}
              url={listicle.url}
              position="listicle-product-list-top-link"
              origin="listicle-override"
              releaseName={listicle.product}
              slug={listicle.slug}
              seller={listicle.product}
              display="link"
              eventProperties={{ partnerType: 'override' }}
            >
              {listicle.price}
            </SimplePrice>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-[14px] desktop:gap-3">
        <SimplePrice
          uuid={listicle._uid}
          url={listicle.url}
          position="listicle-product-list-top"
          origin="listicle-override"
          releaseName={listicle.product}
          slug={listicle.slug}
          seller={listicle.product}
          display="button"
          eventProperties={{ partnerType: 'override' }}
        />
        <a href={`#${listicle._uid}`} className="flex items-center justify-center gap-2 self-stretch text-center text-md tracking-tight text-navy">
          Read more
          <IconChevronDownSm />
        </a>
      </div>
    </div>
  </div>
);

const Experts: React.FC<{
  authors: Author[];
  reviewers: Author[];
}> = ({ authors, reviewers }) => (
  <div>
    <div className="flex gap-6 md:gap-6">
      <div className="flex flex-shrink-0">
        {authors.length === 2 && (
          <>
            {authors[1].avatar_url && (
              <ImageWrapper image={authors[1].avatar_url} imgProps={{ className: 'rounded-full !static !w-[64px] !h-[64px]' }} origin="article-header" />
            )}
            {authors[0].avatar_url && (
              <ImageWrapper
                image={authors[0].avatar_url}
                imgProps={{ className: 'rounded-full !relative !w-[64px] !-ml-[20px] shadow-[0_0_0_4px_#FEF9F0] !h-[64px]' }}
                origin="article-header"
              />
            )}
          </>
        )}
        {authors.length === 1 && authors[0].avatar_url && (
          <ImageWrapper image={authors[0].avatar_url} imgProps={{ className: 'rounded-full !static !w-[64px] !h-[64px]' }} origin="article-header" />
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-6 md:gap-4">
        <div className="flex flex-col gap-1 text-navy">
          <div className="text-[16px] leading-[140%] tracking-tight">Written by</div>
          {authors.map((author) => (
            <NextLink prefetch={false} key={author.id} className="text-[15px] leading-[120%] tracking-tight underline" href={`/authors/${author.slug}`}>
              {author.name}
            </NextLink>
          ))}
        </div>
        {reviewers.length > 0 && (
          <div className="flex flex-col gap-1 text-navy">
            <div className="text-[16px] leading-[140%] tracking-tight">Reviewed by</div>
            {reviewers.map((reviewer) => (
              <NextLink prefetch={false} key={reviewer.id} className="text-[15px] leading-[120%] tracking-tight underline" href={`/authors/${reviewer.slug}`}>
                {reviewer.name}
              </NextLink>
            ))}
          </div>
        )}
      </div>
    </div>
    <div className="mt-[24px] text-[16px] leading-[140%] tracking-tight text-navy">
      Our expert team of audiologists carefully selects, researches, and rigorously tests the products we feature. If you make a purchase through our links, we
      may earn a commission.
    </div>
  </div>
);

const WhyTrustUs: React.FC<{ className: ClassNameValue }> = ({ className }) => (
  <div className={tw('flex flex-col gap-[24px] rounded-[20px] bg-navy p-[24px] text-white md:gap-8 md:px-6 md:py-8', className)}>
    <div className="flex items-center justify-between">
      <div className="text-[24px] leading-[120%]">Why trust us?</div>
      <ImageWrapper image="logo-white.svg" imgProps={{ className: 'rounded-full !static !w-[122px] !h-[20px]' }} origin="article-header" />
    </div>
    <div className="flex flex-col gap-1">
      <ul className="flex justify-between gap-[12px]">
        <li className="flex flex-col gap-2">
          <div className="text-[32px] font-medium leading-[120%] tracking-tight md:text-[40px]">250+</div>
          <div className="text-[12px] font-light leading-[140%] tracking-tight text-white/80 md:text-[14px] md:text-white">Hours of audio recordings</div>
        </li>
        <li className="flex flex-col gap-2">
          <div className="text-[32px] font-medium leading-[120%] tracking-tight md:text-[40px]">80+</div>
          <div className="text-[12px] font-light leading-[140%] tracking-tight text-white/80 md:text-[14px] md:text-white">Hearing Aid models tested</div>
        </li>
        <li className="flex flex-col gap-2">
          <div className="text-[32px] font-medium leading-[120%] tracking-tight md:text-[40px]">5</div>
          <div className="text-[12px] font-light leading-[140%] tracking-tight text-white/80 md:text-[14px] md:text-white">Industry leading audiologists</div>
        </li>
      </ul>
    </div>
    <div className="flex justify-end">
      <a href="/expert-vetting-our-hearing-aid-review-process" className="flex items-center gap-[16px]">
        <span className="text-base leading-[140%] tracking-tight">How we test</span>
        <span className="rounded-full bg-lapis p-[10px] md:p-[15px]">
          <IconArrowRight className="text-white" />
        </span>
      </a>
    </div>
  </div>
);

const extractAuthors = ({
  blok,
  apiAuthors,
}: {
  blok: N4ArticleStoryblok;
  apiAuthors: Record<number | string, Author>;
}): { primary: Author[]; reviewers: Author[] } => {
  const authors: { primary: Author[]; reviewers: Author[] } = {
    primary: [],
    reviewers: [],
  };
  if (!apiAuthors) {
    return authors;
  }
  if (blok.primary_author) {
    authors.primary.push(apiAuthors[blok.primary_author]);
  }
  if (blok.additional_authors) {
    authors.primary.push(...blok.additional_authors.map((a: string) => apiAuthors[a]));
  }
  if (authors.primary.length === 0) {
    if (blok.author_other) {
      authors.primary.push({ id: -1, name: blok.author_other });
    } else {
      authors.primary.push({ id: -1, name: 'Staff' });
    }
  }

  if (blok.expert_reviewers) {
    authors.reviewers.push(...blok.expert_reviewers.map((r: string) => apiAuthors[r]));
  }
  return authors;
};

interface ArticleHeaderProps {
  blok: N4ArticleStoryblok;
  story: Story;
  listicles: (ListicleType | ListicleOverrideType)[];
  className: ClassNameValue;
}

const isListicleProduct = (object: ListicleType | ListicleOverrideType): object is ListicleType => {
  return 'release' in object;
};

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({ blok, story, listicles, className }) => {
  const { api } = useApiData();
  const [authors, setAuthors] = React.useState(extractAuthors({ blok, apiAuthors: api.authors }));
  const [published, setPublished] = React.useState('');
  const [updated, setUpdated] = React.useState('');
  const [isViewMore, setIsViewMore] = React.useState(false);

  React.useEffect(() => {
    setPublished(formatDate(blok.published || story.published_at || story.created_at));
    if (blok.updated) {
      setUpdated(formatDate(blok.updated));
    }
    const fn = async () => {
      await loadAuthors({ story, api, log });
      setAuthors(extractAuthors({ blok, apiAuthors: api.authors }));
    };
    fn();
  }, [story, blok, api]);

  const { primary, reviewers } = authors;

  log.debug('blok: %o', blok);
  return (
    <div className={tw('self-stretch', className)}>
      <ArticleTitle blok={blok} story={story} published={published} updated={updated} className="" />
      <div className="mt-8 flex flex-col gap-14 self-stretch md:mt-16 md:flex-row laptop:gap-[140px]">
        <section className="order-1 mb-8 md:mb-0 md:basis-[50%] md:pb-16 lg:flex-1">
          <div className="text-2xl font-medium tracking-tight text-navy">Our Recommendations</div>
          <div className="">
            {listicles &&
              listicles.map((listicle, index) =>
                isListicleProduct(listicle) ? (
                  <ListicleProduct
                    key={listicle._uid}
                    listicle={listicle as ListicleType}
                    className={!isViewMore && index >= FEATURES_TO_SHOW_INITIALLY && 'hidden'}
                    blok={blok}
                  />
                ) : (
                  <ListicleOverride
                    key={listicle._uid}
                    listicle={listicle as ListicleOverrideType}
                    className={!isViewMore && index >= FEATURES_TO_SHOW_INITIALLY && 'hidden'}
                  />
                )
              )}
          </div>
          <ViewMoreButton
            isViewMore={isViewMore}
            onViewMoreButtonClick={() => setIsViewMore((currentIsViewMore) => !currentIsViewMore)}
            className="-mt-[1px] border-t border-navy-10 pt-6"
            negativeStateText="Show more"
            positiveStateText="Show fewer"
            hidden={(listicles?.length || 0) <= FEATURES_TO_SHOW_INITIALLY}
          />
          <WhyTrustUs className="mx-auto mt-12 max-w-[640px] md:mb-8 md:hidden" />
        </section>
        <section className="order-0 md:order-1 md:basis-[50%] lg:basis-[440px]">
          <div className="mb-[24px] text-2xl font-medium tracking-tight text-navy md:mb-[20px]">Our Experts</div>
          <Experts authors={primary} reviewers={reviewers} />
          <WhyTrustUs className="mt-16 hidden md:mt-8 md:flex" />
        </section>
      </div>
    </div>
  );
};
